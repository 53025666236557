/* EXTERNAL GLOBAL CSS */
// Bootstrap v4.5.3
@import "~bootstrap/scss/bootstrap";
// Bootstrap v3.3.7
@import "../public/assets/css/bootstrap-v3";
// Font styles
@import "./styles/typography.scss";

/* CORE-STRCTURE -------------------------------------------------------- */
body {
  width: 100%;
  margin: 0 auto 0 auto;
  padding: 0;
}

#header-inner,
#mainNavBar-inner,
#cedcd-main-content,
#header-nci,
#edit_intro {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

#wrapper-inner {
  width: 100%;
  padding: 0;
  margin: 0 auto 0 auto;
}

.no-gutters {
  padding-left: 0;
  padding-right: 0;
}

/* HEADER-CEDCD -------------------------------------------------------- */
#cedcd-bg-wrapper {
  width: 100%; /*updated 9/23/14 - 96% */
  margin: 0 auto;
}

#header {
  width: 100%;
  height: 100px;
  background-color: #62269e;
}

/* FOOTER -------------------------------------------------------- */
#footer {
  width: 100%;
  margin: 0 0 0 0;
  padding: 30px 0 30px 0;
  border-top: 3px #135b5d solid;
  background-color: #135b5d;
}

.message-mid {
  width: 20%;
  visibility: hidden;
  margin-left: 40%;
  position: absolute;
  top: 210px;
  left: 0px;
  padding: 4px;
  background: #135b5d;
  color: white;
  transform: translate(0, 0);
  transition: all 0.4s ease-out;
}

.message-mid.fade-away {
  visibility: visible;
  transform: translate(0, 100%);
  z-index: 10000;
}

.message-top {
  width: 20%;
  visibility: hidden;
  margin-left: 40%;
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 4px;
  background: #135b5d;
  color: white;
  transform: translate(0, 0);
  transition: all 0.4s ease-out;
}

.message-top.fade-away {
  visibility: visible;
  transform: translate(0, 100%);
}

.shadow {
  width: 100%;
  background-color: gray;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  opacity: 0.5;
}

.pop-content.open {
  transform: translate(0, 0);
  visibility: visible;
}

.pop-content {
  width: 960px;
  max-width: 100%;
  min-height: 700px;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  z-index: 10500;
  position: fixed;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 3rem;
  transform: translate(100%, 0);
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.inputWriter {
  width: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
  background: transparent;
}

.inputUnderscore {
  font-size: 1.5rem;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid grey;
  outline: none;
  background: transparent;
}

.errorInputUnderscore {
  font-size: 1.5rem;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid red;
  outline: none;
  background: transparent;
}

.pop-close {
  min-width: 10px;
  padding: 4px 10px;
}

.tour-close {
  min-width: 10px;
  padding: 0px 6px;
}

/* MAIN CONTENT AREA -------------------------------------------------------- */
#cedcd-main-content {
  padding: 0 0 30px 0;
  background: #ffffff;
}

/* Filter Panels */
.panel {
  margin-bottom: 0rem;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 0px;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.panel-default {
  border-color: #01857b;
}

.panel-default > .panel-heading {
  background-color: #01857b;
  border-color: #01857b;
}

#cedcd-home-filter .filter-inner {
  display: flex;
}

#cohortSelectPage #cedcd-home-filter {
  margin-bottom: 0;
}

#selectPage #cedcd-home-filter {
  margin-bottom: 2rem;
}

.filter.row {
  display: flex;
}

.filterCol {
  border-right: 1px solid #457f7f;
  min-height: 95px;
}

.filterCol.last {
  border-right: none;
}

#filterLabels #filter-inner {
  border: #135b5d 1px solid;
}

.filter-block.lockedFilter .panel-body {
  background-color: rgba(0, 149, 137, 0.125);
}

.filter-component-dropdown {
  width: 100%;
  min-width: 100px;
  max-height: 600px;
  overflow-y: auto;
  z-index: 9001 !important;
}

.selectOr {
  /* Probably not needed */
  width: 16px;
  margin: 11px 1% 0 1%;
  padding: 0 0 0 0;
  float: left;
}

/* Cohort Details Floating Submit Button */
#floatingSubmitButtonContainer {
  position: absolute;
  bottom: 1rem;
  background-color: rgba(0, 149, 137, 0.75);
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 9000;
  transition: all 300ms ease-out;
}

#floatingSubmitButtonContainer.atHome {
  position: absolute;
}

#floatingSubmitButtonContainer.offScreen {
  position: absolute;
}

#floatingSubmitButtonContainer.floatingFixed {
  position: fixed;
  bottom: 0;
}

.floatingFixedTop {
  position: fixed !important;
  top: 0px !important;
}

.fadein,
.fadeout {
  opacity: 0;
  -moz-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  -webkit-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}

.fadein {
  opacity: 1;
}

/********************/
#cedcd-home-cohorts-inner,
#cedcd-cohorts-inner {
  min-height: 491px;
  background-color: #e8e8e6;
  padding-top: 1rem;
  padding-bottom: 40px; /* to accuont for floating button */
}

#cedcd-cohorts-inner.activeArea {
  background-color: rgba(98, 38, 158, 0.125);
}

// #cedcd-home-cohorts-inner .table-inner {
// }

.interiorTable {
  max-width: 100%;
}

.cedcd-table label {
  height: 40px;
  line-height: 40px;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  color: #05746c;
  background-color: #e8e8e6;
}

.tableArrow {
  float: right;
}

.tableArea {
  overflow-x: scroll;
}

.compareGroup-header {
  /* need a more general class for expand/contract triggers */
  cursor: pointer;
}

/* TABLE PARTS */
.table-description {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  padding-left: 15px;
  padding-right: 15px;
}

.tableTopMatter {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

#tableExport {
  text-align: right;
  padding-top: 0.5rem;
}

.table-pager {
  padding-bottom: 1rem;
}

#table-intro {
  border: 1px solid #e8e8e6;
  border-top: none;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

#data-table {
  padding-bottom: 2rem;
}

/* table styles */
table,
.cedcd-table tr,
.cedcd-table td,
.cedcd-table th {
  /*clears default table styles */
  font-size: 1.5rem;
}

.cedcd-table {
  width: 100%;
  margin: 0 auto 0 auto;
  padding-bottom: 20px;
  min-width: 500px;
  overflow: auto;
}

table {
  text-align: left;
  width: 100%;
  background: #ffffff;
  padding: 0 0 0 0;
  border: 1px #b7b5b0 solid;
  border-collapse: collapse;
}

.cedcd-table table {
  margin: 5px auto 0 auto; /*removed by Chris, put back in by Alex to fix sticky header issue, might need to come back out based on Chris's reasoning*/
}

.cedcd-table td,
.cedcd-table th {
  height: 36px;
}

th,
td,
.table > thead > tr > th {
  padding: 8px 10px 8px 10px;
  border: 1px #b7b5b0 solid;
}

.table > thead > tr > th {
  vertical-align: middle;
}

tr {
  background: #fff;
  transition: background 200ms ease-out;
}

tr:hover {
  background: #e9f6f5;
}

table thead th {
  /*background: rgba(0, 149, 138, 1);*/
  background: #01857b;
  color: white;
  font-weight: normal;
  /*  border: 1px rgba(102,102,93,1.00) solid;
  border-left: 1px #B28FC2 solid;
  border-right: 1px #B28FC2 solid;*/
}

#enrollment_table thead th {
  text-align: center;
}

table thead th a {
  color: white;
  font-weight: bold;
  text-decoration: underline;
}

/*table thead th a:focus {
  color: #fff;
  background-color: #135B5D;
}*/

#cedcd-home-cohorts .cedcd-table table tr.col-header th a {
  text-decoration: none;
}

.cedcd-table th.select-checkbox {
  font-size: 75%;
}

.cedcd-table td.select-checkbox {
  text-align: center;
  margin: 0;
  padding: 0;
}

th.sortable,
.table > thead > tr > th.sortable {
  padding: 0;
}

tr.first-row {
  border-top: 1px #b18dce solid;
}

th.compareChildRecord {
  padding-left: 30px !important;
  font-weight: normal !important;
}

tbody tr a:hover {
  color: #23527c;
  text-decoration: underline;
}

tr.compare-section-hidden {
  display: none;
}

tr.compare-child-row-hidden {
  display: none;
}

tr.compare-row {
  word-wrap: break-word;
}

tr.compare-row th,
tr.compare-row td {
  text-align: left;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

tr.compare-parent-row th,
tr.compare-parent-row td {
  text-align: left;
}

tr.compare-child-row th,
tr.compare-child-row td {
  display: table-cell;
  text-align: left;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
}

#compareGridView th {
  font-weight: normal;
}

th.compareGroup-header {
  font-weight: 700 !important;
  background-color: #e8e3f0;
  border-top: 1px #b7b5b0 solid;
  padding-top: 14px;
  padding-bottom: 14px;
  border-left: 0px;
}

th.comRowHeader,
#edit_cohort tbody th {
  background: #e8e3f0;
}

th.comRowHeader:hover {
  background: #e8e3f0 !important;
}

.table-col-5perc {
  width: 5%;
  min-width: 60px;
}

.table-col-10perc {
  width: 10%;
  min-width: 100px;
}

.table-col-15perc {
  width: 15%;
  min-width: 120px;
}

.table-col-20perc {
  width: 20%;
  min-width: 200px;
}

.table-col-30perc {
  width: 30%;
  min-width: 160px;
}

.table-col-40perc {
  width: 40%;
  min-width: 180px;
}

.table-col-50perc {
  width: 50%;
  min-width: 200px;
}

.table-col-60perc {
  width: 60%;
  min-width: 300px;
}

.table-col-100perc {
  width: 100%;
  min-width: 500px;
}

.table-col-150 {
  min-width: 150px;
}

.table-col-360 {
  width: 360px;
  max-width: 360px;
  min-width: 360px;
}

.table-col-400 {
  width: 400px;
  max-width: 400px;
  min-width: 400px;
}

.table-col-600 {
  width: 600px;
  max-width: 600px;
  min-width: 600px;
}

/* DATA TABLES -------------------------------------------------------- */
.section-expand {
  display: inline;
  background: url("/assets/img/arrow-square.png") no-repeat 1% 4px;
  min-width: 15px;
  min-height: 15px;
  float: right;
}

.section-expand.active {
  background-position: 1% -24px;
}

.row-expand {
  /* Comes into place in Cancer Info & other sub-section of compare.aspx */
  margin-left: 10px;
  display: inline-block;
  background: url("/assets/img/arrow-square.png") no-repeat 1% 4px;
  width: 15px;
  height: 15px;
}

.row-expand.active {
  background-position: 1% -24px;
}

/* ABOUT ---------------------------------------------------------------- */
#about-col-2 {
  background-color: #e8e8e6;
  padding-bottom: 2rem;
}

/* other stuff */
.is-sticky #sticker {
  width: 180px;
}

#sticker #cedcd-main-nav {
  margin: 10px 0 0 2%;
}

// #cedcd-main-nav .main-nav {
// }

#sticker {
  border: #135b5d 1px solid;
  border-radius: 0 10px 10px 0;
}

#sticker h3 {
  background-color: #135b5d;
  color: #fff;
}

/* ------------------------ */
#mainNavBar {
  width: 100%;
  background-color: #135b5d;
  margin-bottom: 2rem;
}

/* table icons */
.icon_modal {
  display: inline;
  text-align: right;
  background: url("/assets/img/ico_modal.png");
  background-repeat: no-repeat;
  background-size: 16px 16px;
  padding: 0 9px 0 9px;
  margin: 0 0 0 10px;
  transition: all 0.3s ease-out;
}

.icon_modal:hover {
  display: inline;
  background: url("/assets/img/ico_modal_hover.png");
  background-repeat: no-repeat;
  background-size: 16px 16px;
  padding: 0 9px 0 9px;
  margin: 0 0 0 10px;
  cursor: pointer;
}

.rightSideFlag {
  position: absolute;
  right: 0;
  transform-origin: bottom right;
  transform: rotate(-90deg);
  z-index: 1000;
}

#helpFlag {
  position: absolute;
  top: 250px;
  right: 0;
  z-index: 1000;
}

/* bootstrap tooltip and popover styles */
.popover {
  padding: 1rem 0;
  border-color: #62269e;
  border-width: 2px;
  box-shadow: 1px 1px 8px rgba(35, 31, 32, 0.7);
  filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=3, OffY=3, Color='#231F20');
}

.popover.start {
  width: 476px;
  max-width: 476px;
  padding: 30px 10px 30px 10px;
}

.popover-title,
.popover-title-override {
  margin: 6px 14px 0 14px;
  padding: 0;
}

.popover-content {
  padding: 6px 14px 12px 14px;
}

.popover.top > .arrow {
  border-top-color: #62269e;
}

.popover.right > .arrow {
  border-right-color: #62269e;
}

.popover.bottom > .arrow {
  border-bottom-color: #62269e;
}

.popover.left > .arrow {
  border-left-color: #62269e;
}

.popover[class*="tour-"] .popover-navigation *[data-role="end"] {
  position: absolute;
  top: 6px;
  right: 6px;
}

.btn-popover-close {
  border: 1px #b0afa9 solid;
  background: #ffffff;
  padding: 1px 4px 3px 4px;
  vertical-align: middle;
  border-radius: 3px;
  transition: all 200ms ease-out;
}

.btn-popover-close:hover,
.btn-popover-close:focus {
  border: 1px #b0afa9 solid;
  background: #efefef;
}

.btn-popover {
  background: #ffffff;
  border-style: Solid;
  border-color: rgba(101, 203, 201, 1);
  border-width: 1px;
  border-radius: 6px;
  transition: all 200ms ease-out;
}

.btn-popover.prev {
  background: url("/assets/img/bg_popover_prev.png") 6px center no-repeat;
  float: left;
  clear: both;
  padding: 2px 10px 2px 16px;
}

.btn-popover.next {
  background: url("/assets/img/bg_popover_next.png") right 6px center no-repeat;
  float: right;
  padding: 2px 16px 2px 10px;
}

.btn-popover:hover {
  background-color: #e8f5f2;
}

.popover[class*="tour-"] .popover-navigation *[data-role="end"].btn-popover.next.popover-last {
  position: relative !important;
  background: url("/assets/img/bg_popover_next.png") right 6px center no-repeat;
  float: right;
  padding: 2px 16px 2px 10px !important;
  margin: 0 !important;
  top: 0 !important;
  right: 0 !important;
}

.popover[class*="tour-"] .popover-navigation *[data-role="end"].btn-popover.next.popover-last:hover {
  background-color: #e8f5f2;
}

.tour-step-background {
  background-color: rgba(255, 255, 255, 0.5);
}

/* SKIP NAV - for accessibility -------------------  */

#skipNav a,
#skipNav a:hover,
#skipNav a:visited {
  position: absolute;
  left: 0px;
  top: -500px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

#skipNav a:active,
#skipNav a:focus {
  position: static;
  width: auto;
  height: auto;
}

/* Cohort Selection Page additions 10-Mar-2015  ------------- */
#inKeyword {
  color: Black;
}

#inKeyword:focus {
  color: Black;
}

.wf_renderBlocks {
  display: inline-block;
  float: left;
}

#filter-inner .bttn_submit {
  white-space: nowrap;
  width: 97px;
  margin: 6px 10px 6px 10px;
  float: right;
  padding: 6px 2px 6px 2px;
}

#filterClear {
  width: 97px;
  margin: 6px 10px 6px 10px;
  padding: 6px 2px 6px 2px;
}

.cedcd-table #table-select-col {
  width: 40px;
}

.cedcd-table #cohort_name {
  width: auto;
}

.cedcd-table #cohort_acronymn {
  width: 100px;
}

.cedcd-table #date_form_completed {
  width: 100px;
}

.cedcd-table #contactCol {
  width: 70px;
}

.cedcd-table #policyCol {
  width: 70px;
}

.cedcd-table #moreCol {
  width: 70px;
}

#cohort_list_container {
  margin: 0 auto;
  padding: 3px 5px 3px 5px;
  background-color: white;
}

.show_cohort_list {
  display: block !important;
  border: 1px solid green;
}

#cohort_list_container label {
  padding-left: 5px;
}

#cohort_list_container_close_btn {
  float: right;
  font-weight: bold;
}

#cancer_options_list {
  margin: 5px 5px 5px 5px;
  overflow: hidden;
  overflow-y: scroll;
  height: 350px;
}

#cancer_options_list input {
  vertical-align: top;
}

#cancer_options_list label {
  padding-left: 5px;
  word-wrap: normal;
}

#cancer_options_list .wrap-label {
  padding-left: 5px;
  word-wrap: normal;
  white-space: normal;
}

#cancer_type_close_btn {
  font-weight: bold;
  float: right;
}

#specimen_options_list label {
  padding-left: 5px;
}

/* css for sticky headers */

.table-col-10perc {
  min-width: 112px;
  /*max-width: 112px;*/
}

a.section-expand {
  pointer-events: none;
}

.compareGroup-header:focus {
  outline: 0px;
}

.fixed-header-column {
  position: absolute;
  top: 33px;
  left: 15px;
  width: 201px;
  /*pointer-events: none;*/
}

.inner-fixed-header-column {
  position: absolute;
  top: 33px;
  left: 15px;
  width: 201px;
  /*pointer-events: none;*/
}

.inner-fixed-header-column .clickable {
  cursor: pointer;
}

.fixed-header-column--double {
  width: 100%;
  top: 153px;
  pointer-events: none;
}

.fixed-header-column--enroll {
  top: 5px;
  left: 0px;
}

.header-column__half {
  display: inline-flex;
  flex-flow: column nowrap;
  vertical-align: top;
}

.column__cell-1,
.column__cell-2 {
  border-left: 1px #b7b5b0 solid;
  border-bottom: 1px #b7b5b0 solid;
}

.table-header {
  height: 50px;
  background-color: #01857b;
  color: white;
  border-left: 1px #b7b5b0 solid;
  border-top: 1px #b7b5b0 solid;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  position: relative;
  font-weight: bold;
}

/*.table-header::after {
    content: attr(data-text);
    background-color: #623494;
    color: white;
    
    border-left: 1px #B28FC2 solid;
    border-right: 1px #B28FC2 solid;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    z-index: 2;
    position: absolute;
    left: -1px;
    top: 0;
    width: calc(100% + 2px);
    width: inherit;
    height: 100%;
}*/

.fixed .table-header::after {
  position: fixed;
  /*width: 202px;*/
  height: inherit;
  left: auto;
  transform: translateX(-12px);
}

.row-header-first {
  font-weight: 700;
  background-color: #e8e3f0;
  /* font-weight: bold; */
  padding: 10px;
  padding-top: 14px;
  padding-bottom: 14px;
  white-space: nowrap;
  width: 360px;
  cursor: pointer;
  position: relative;
  top: 0;
  left: 0;
}

.row-header {
  font-weight: 700;
  background-color: #e8e3f0;
  /* font-weight: bold; */
  border-top: 1px solid #b7b5b0;
  padding: 10px;
  padding-top: 14px;
  padding-bottom: 16px;
  white-space: nowrap;
  width: 360px;
  cursor: pointer;
  position: relative;
  top: 0;
  left: 0;
}

/*.row-header:first-child {
  padding-top: 14px;
}*/

.body-header {
  text-align: left;
  border: 1px #b7b5b0 solid;
  border-bottom: 1px solid transparent;
  background-color: white;
  align-items: center;
  transition: background 200ms ease-out;
  padding-left: 10px;
  display: table;
}

.body-header span {
  vertical-align: middle;
  display: table-cell;
}

.body-header:last-child {
  border-bottom: 0px solid transparent;
}

.table-section--active .body-header {
  display: flex;
}

tr.compare-row--hover,
.body-header--hover {
  background-color: #e9f6f5;
}

.fixed-header-row-wrapper {
  position: absolute;
  top: 33px;
  width: calc(100% - 30px);
  overflow-x: hidden;
  /*margin-top: 5px;*/
}

.fixed .fixed-header-row-wrapper,
.fixed-header-row-wrapper--scrolling {
  pointer-events: auto;
}

.fixed-header-row--enroll {
  width: auto;
  overflow-x: visible;
  top: 0;
  left: 0;
}

.fixed-header-row--corner {
  pointer-events: auto;
}

.fixed-header-row {
  white-space: nowrap;
}

.fixed-header-row-caption {
  position: relative;
  top: 0;
  left: 0;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #623494;
  background-color: #e8e8e6;
}

.xingFloating {
  height: 39px;
  width: 360px;
  position: absolute;
  display: inline-flex;
  align-items: center;
  padding: 8px 10px;
  background: #01857b;
  color: white;
  font-weight: bold;
  border: 1px #b7b5b0 solid;
}

.fixed-row__cell {
  display: inline-flex;
  align-items: center;
  padding: 8px 10px;
  background: #01857b;
  color: white;
  font-weight: bold;
  border: 1px #b7b5b0 solid;
}

.fixed-row__cell a {
  text-decoration: underline;
  color: white !important;
}

.column__cell-1 {
  font-weight: bold;
  letter-spacing: -0.4px;
  padding: 8px 10px;
  background-color: #e9f6f5;
  display: flex;
  align-items: center;
}

.column__cell-2 {
  font-weight: bold;
  letter-spacing: -0.4px;
  padding: 8px 10px;
  background-color: white;
  display: flex;
  align-items: center;
}

.panel-heading--fixed {
  position: fixed;
  top: 0;
  z-index: 3;
  max-width: 1155px;
}

.parent--fixed {
  padding-top: 25px;
}

.fixed-header-row-wrapper--textwrap .fixed-header-row {
  white-space: normal;
}

.fixed-header-row-wrapper--textwrap .fixed-row__cell {
  vertical-align: top;
  font-weight: normal;
  text-decoration: none;
}

.fixed-header-row-wrapper--phase-2 {
  /*cross browser support*/
  left: auto;
  left: inherit;
  left: initial;
}

.fixed-header-row-wrapper--phase-2 .fixed-header-row-caption {
  display: block;
  font-size: 1.5rem;
  font-weight: normal;
  color: #777;
  background-color: white;
  padding-top: 8px;
  padding-bottom: 8px;
  height: auto;
  box-sizing: content-box;
}

.fixed-header-row-wrapper--scroll.fixed-header-row-wrapper--phase-2 .fixed-header-row-caption {
  display: block;
}

.validation-error .fixed-header-row-caption span {
  background-color: #f2dede;
  color: #a94442;
}

.hidden-section {
  display: none;
}

.hidden-section--visible {
  display: block;
}

.body-header--parent .row-expand {
  width: 18px;
  pointer-events: none;
  flex-shrink: 0;
}

.body-header--child {
  font-size: 14px;
  padding-left: 30px;
}

.reactTooltip {
  cursor: pointer;
  font-weight: bold;
  color: #01857b;
}

.customeTheme {
  color: white !important;
  background-color: #01857b !important;
}
.place-top {
  width: 400px;
}
.place-top:after {
  border-top-color: #01857b !important;
  border-top-style: solid !important;
  border-top-width: 6px !important;
}

// .remove-css
// {

// }

@media (max-width: 1310px) {
  .panel-heading--fixed {
    border-right: 85px solid transparent;
    border-right-color: #e8e8e8 !important;
  }
}

@media (max-width: 1200px) {
  .panel-heading--fixed {
    max-width: initial;
    border-right: 130px solid transparent;
    border-right-color: #e8e8e8 !important;
  }
}

/* Utils */
.table-nowrap th,
.table-nowrap td {
  white-space: nowrap;
}

.table-valign-middle td,
.table-valign-center th {
  vertical-align: middle !important;
}

.text-valign-center {
  display: flex;
  align-items: center;
}

.bg-grey {
  background-color: #eee;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

.border-0 {
  border: 0;
  border-radius: 0;
  box-shadow: none;
}

.resize-vertical {
  resize: vertical;
}

/* Forms CSS Document */

#contact-main input[type="text"],
#contact-main select,
#contact-main textarea {
  display: block;
  border: 1px solid #b7b5a8;
  padding: 5px 1%;
  width: 90%;
}

#contact-main input:focus,
#contact-main textarea:focus {
  background: #f9f7ef;
  outline: none;
}

.contact-us-field {
  margin-bottom: 2rem;
}

.required {
  padding-left: 5px;
}

.require-input:after {
  content: "*";
}

.input-invalid label {
  color: orangered;
}

.input-invalid input {
  background-color: yellow;
}

// .field-required {
// }

.field-required label {
  color: red;
}

.field-required label:after {
  content: " required field";
}

// .field-valid {
// }

.field-invalid label {
  color: red;
}

.field-invalid label:after {
  content: " field not valid";
}

#reviewerRejectionRationale {
  margin-bottom: 1.5rem;
}

/* search */
.search-wrapper {
  padding: 0;
}

.search-wrapper label {
  /* this should probably be handled different for 508 */
  display: none;
}

.search-wrapper .searchField input {
  /* can we do away with the wrappers for the field and button? */
  background: #ffffff;
  padding: 3px 2% 2px 1%;
  margin: 0 0 0 0;
  width: 95%;
  display: inline-block;
  border: 2px rgba(0, 149, 138, 1) solid;
  transition: all 0.4s ease-in-out;
}

.search-wrapper .searchField input:focus {
  outline: none;
  background: #f9f7ef;
}

// REACT DATEPICKER
.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker__input-container input {
  width: 100% !important;
}

// BOOTSTRAP V4 OVERRIDES

.form-label {
  font-weight: 600;
}

.required-label:after {
  content: "*";
  color: red;
  // margin-left: 0.25rem;
}

.bg-light-grey {
  background-color: #f1f1f1;
}

.text-decoration-underline {
  text-decoration: underline;
}

.table input.form-control.input-number[type="number"] {
  border: none;
  box-shadow: none;
  background-color: transparent;
  text-align: right;
}

// input form-control placeholder color
.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c6c6c6;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c6c6c6;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c6c6c6;
}

.inputWriter::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c6c6c6;
  opacity: 1; /* Firefox */
}

.inputWriter:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c6c6c6;
}

.inputWriter::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c6c6c6;
}

input[type="file"] {
  color: transparent;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.c-pointer {
  cursor: pointer !important;
}

.resize-disabled {
  resize: none;
}

.form-check-label {
  display: inline !important;
}

.modal-backdrop {
  width: 100%;
  height: 100%;
}
