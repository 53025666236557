.dropdown,
.miniDropdown {
  position: relative;
  display: inline-block;
  height: inherit;
  box-sizing: border-box;
}
.miniDropdown .dropdown:hover {
  background-color: #01857b;
}

#dropHeader {
  padding-bottom: 0;
  /*border: 1px solid red;*/
  height: inherit;
}

.admindropdown-content {
  display: none;
  position: absolute;
  left: -52px;
  text-align: left !important;
  margin-top: 10px;
  background-color: #135b5d;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.admindropdown-content a {
  color: black;
  padding: 8px 10px;

  text-align: left !important;
  text-decoration: none;

  display: block;
}

.admindropdown-content a:hover {
  background-color: #01857b;
}

.dropdown:hover .admindropdown-content {
  display: block;
}

ul#mainNav li.icon {
  display: none;
}

@media screen and (max-width: 768px) {
  ul#mainNav li:not(:first-child) {
    display: none;
  }
  ul#mainNav {
    border-bottom: 1px solid gray;
  }
  ul#subNav {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
  }

  ul#subNav li {
    width: 100%;
    margin: 0;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #135b5d;
  }

  ul#subNav li:hover,
  ul#subNav li.active {
    background-color: #01857b;
    cursor: hand;
  }

  ul#subNav li.miniDropdown a.miniLink:hover {
    background-color: #135b5d;
  }

  ul#subNav li a {
    color: white;
    background-color: #135b5d;
  }

  ul#mainNav li.icon {
    position: relative;
    height: 100%;
    font-size: 25px;
    display: block;
    text-align: left;
    line-height: 1.5em;
  }

  ul#mainNav ul#subNav li.icon:hover {
    background-color: #01857b;
    cursor: hand;
  }
}
