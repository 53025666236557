.login-button {
  display: inline-block;
  text-align: center;
  /* vertical-align: bottom; */
  padding: 8px 16px;
  border: 1px solid #9b9999;
  border-radius: 8px;
  background: #dedada;
  background: -webkit-gradient(linear, left top, left bottom, from(#dedada), to(#9b9999));
  background: -moz-linear-gradient(top, #dedada, #9b9999);
  background: linear-gradient(to bottom, #dedada, #9b9999);
  font: normal normal bold 14px arial;
  color: #570757;
  text-decoration: none;
}
.login-button:hover,
.login-button:focus {
  border: 1px solid #dedada;
  background: #ffffff;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#bab8b8));
  background: -moz-linear-gradient(top, #ffffff, #bab8b8);
  background: linear-gradient(to bottom, #ffffff, #bab8b8);
  color: #570757;
  text-decoration: none;
}
.login-button:active {
  background: #9b9999;
  background: -webkit-gradient(linear, left top, left bottom, from(#9b9999), to(#9b9999));
  background: -moz-linear-gradient(top, #9b9999, #9b9999);
  background: linear-gradient(to bottom, #9b9999, #9b9999);
}
