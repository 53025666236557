.row-for-home-grid {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex: 50%;
  /* height: 50%; */
  height: 420px;
  min-height: 500px;
  width: 100%;
}

/* Create two equal columns that sits next to each other */
.column-for-home-grid {
  margin: 15px;
  padding-bottom: 15px;
  /*border: 1px solid #135B5D;*/
  min-width: 250px;
  width: 100%;
  height: 94%;
}

.animation-card {
  width: 100%;
  height: 100%;
  margin: 10px;
  /*border: 1px solid black;*/
  background-color: white;
  color: black;
  box-shadow: 0 4px 8px 5px rgba(0, 0, 0, 0.2);
}
.animation-card:link {
  /*border: 1px solid black;*/
  text-decoration: none;
  color: black;
}

.animation-card:hover {
  text-decoration: none;
  transform: scale(1.04);
  transition: all 200ms;
  box-shadow: 0px 12px 20px 10px rgba(0, 0, 0, 0.2);
}

.inner-card {
  width: 100%;
  height: 100%;
  margin: 10px;
  /*border: 1px solid black;*/
  background-color: white;
  color: black;
  padding: 15px;
  box-shadow: 0 4px 8px 5px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

.inner-card:link {
  /*border: 1px solid black;*/
  text-decoration: none;
  padding: 15px;
  color: black;
}

.inner-card:hover {
  text-decoration: none;
  margin: 5px;
  box-shadow: 5px 12px 20px 10px rgba(0, 0, 0, 0.2);
}
img {
  border-radius: 5px;
}
.container {
  padding: 2px 16px;
}

a.link-text:link {
  color: black;
  text-decoration: none;
}

a.link-text:visited {
  color: black;
  text-decoration: none;
}

a.link-text:hover {
  text-decoration: none;
  color: black;
}

a.link-text:active {
  text-decoration: none;
  color: black;
}

.underline-style {
  text-decoration: underline;
}

img {
  border-radius: 5px 5px 0 0;
}

.container {
  padding: 2px 16px;
}
#welcome-title {
  padding: 0 1.5rem;
}

.card-text {
  text-align: center;
  width: 90%;
  color: white;
  font-size: 18px;
  line-height: 1.4;
}
.card-header {
  /*padding: 20px 0 0 0;*/
  color: white;
  font-weight: bold;
  border-bottom: none !important;
  background-color: rgba(0, 0, 0, 0) !important;
  padding: 0 0 0 0 !important;
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}
.card-top-coloring {
  width: 100%;
  height: 35%;
}
