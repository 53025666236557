.zero-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.form-group {
  margin-bottom: 2rem !important;
}

.form-group.has-error label {
  /* color: red; */
}

.question {
  margin-bottom: 0.5rem;
}

.specify {
  margin-top: 0.5rem;
}

.error-input {
  color: red;
  line-height: 2em;
}

.error {
  color: red;
}

.checkbox-padding {
  width: 50px;
}

.click-width {
  width: 30px;
}
