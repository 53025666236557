.modal-body span {
  font-size: 1.5rem;
}

.fileModalHeader {
  padding: "0";
}

.modal-title {
  padding: "0";
}

.modal-body {
  padding: 0 0 0 0 !important;
}
